import moment from 'moment-timezone';
import React, { Component } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './App.css';

class App extends Component {
  render() {
    return (
      <div id="container">
        <Clock />
        <Calendar />
        <Calendar activeStartDate={moment().add(1, 'months').startOf("month").toDate()} />
      </div>
    );
  }
}

class Clock extends Component {
  state = {
    dayOfYear: moment().dayOfYear(),
    localDateTime: moment(),
    utcDateTime: moment.utc()
  };

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    let now = moment()
    let doy = now.dayOfYear();

    if(this.state.dayOfYear !== doy) {
      window.location.reload();
    }

    this.setState({
      dayOfYear: doy,
      localDateTime: now,
      utcDateTime: moment.utc()
    });
  }

  render() {
    return (
      <div className="clock">
        <div id="local">
          <div className="time">
            {this.state.localDateTime.format("HHmm")}
          </div>
          <div className="date">
            {this.state.localDateTime.format("YYYY-MM-DD")} 
          </div>
          <div className="date-extended">
            {this.state.localDateTime.format("ddd | ww")} | {this.state.localDateTime.dayOfYear()}
          </div>
        </div>
        <div id="summaries">
          <div className="summary">
            <div className="name">UTC</div>
            <div className="time">
              {this.state.utcDateTime.format("HHmm")}
            </div>
          </div>
          <div className="summary">
            <div className="name">Prague</div>
            <div className="time">
              {this.state.utcDateTime.clone().tz('Europe/Prague').format("HHmm")}
            </div>
          </div>
          <div className="summary">
            <div className="name">London</div>
            <div className="time">
              {this.state.utcDateTime.clone().tz('Europe/London').format("HHmm")}
            </div>
          </div>
          <div className="summary">
            <div className="name">Sao Paulo</div>
            <div className="time">
              {this.state.utcDateTime.clone().tz('America/Sao_Paulo').format("HHmm")}
            </div>
          </div>
          <div className="summary clear-left">
            <div className="name">New York</div>
            <div className="time">
              {this.state.utcDateTime.clone().tz('America/New_York').format("HHmm")}
            </div>
          </div>
          <div className="summary">
            <div className="name">NOLA</div>
            <div className="time">
              {this.state.utcDateTime.clone().tz('America/Chicago').format("HHmm")}
            </div>
          </div>
          <div className="summary">
            <div className="name">Seattle</div>
            <div className="time">
              {this.state.utcDateTime.clone().tz('America/Los_Angeles').format("HHmm")}
            </div>
          </div>
          <div css="clear:both;"></div>
        </div>
      </div>
    );
  }
}

export default App;